const color_light = '#fff';
const color_dark = '#1d1d1d';

get_fontsize = function(place) {
    switch (place) {
      case 'country':
        return 24;
      case 'city':
        return 22;
      case 'town':
        return 18;
      case 'village':
        return 18;
    }
  };

  basemap_places_style = new ol.style.Style({
    text: new ol.style.Text({
      font: 'Open Sans',
      fontWeight: 600,
      fill: new ol.style.Fill({
        color: color_light,
      }),
      stroke: new ol.style.Stroke({
        color: color_dark,
        width: 2
      }),
      text: ''
    })
  });

  basemap_underline = new ol.style.Style({
    stroke: new ol.style.Stroke({
      color: [0, 0, 0, .5],
      width: 6,
    }),
  });

  basemap_undercoord = new ol.style.Style({
    stroke: new ol.style.Stroke({
        lineDash: [1, 5, 10, 5],
        color: color_dark,
        width: 3,
    }),
  });

  basemap_places = function(feature, resolution) {
    var font, name, place, text;
    name = feature.get('name:en');
    if (!name) {
      name = feature.get('name');
    }
    place = feature.get('place');
    font = (get_fontsize(place)) + "px 'opensans_light', sans-serif";
    text = place === 'country' ? name.toUpperCase() : name;
    basemap_places_style.getText().setFont(font);
    basemap_places_style.getText().setText(text);
    return basemap_places_style;
  };

  basemap_coastline = new ol.style.Style({
    stroke: new ol.style.Stroke({
      color: color_light,
      width: 2
    })
  });

  basemap_countries = new ol.style.Style({
    stroke: new ol.style.Stroke({
      lineDash: [2, 5],
      color: color_light,
      width: 2
    })
  });

  // basemap_land = new ol.style.Style({
  //   fill: new ol.style.Fill({
  //     color: '#fff'
  //   })
  // });

  basemap_coordlines = new ol.style.Style({
    stroke: new ol.style.Stroke({
      lineDash: [1, 5, 10, 5],
      color: color_light,
      width: 1
    })
  });

  basemap_coords_style = new ol.style.Style({
    text: new ol.style.Text({
      font: "15px 'opensans_regular', sans-serif",
      fill: new ol.style.Fill({
        color: color_light,
      }),
      stroke: new ol.style.Stroke({
        color: color_dark,
        width: 2
      }),
      text: ''
    })
  });

  basemap_coords = function(feature, resolution) {
    var text;
    text = feature.get('label').replace('-', '');
    basemap_coords_style.getText().setText(text);
    return basemap_coords_style;
  };

  window.style_basemap = function(feature, resolution) {
    var layer;
    layer = feature.get('layer');
    switch (layer) {
      case 'coastline':
        return [basemap_underline, basemap_coastline];
      case 'places':
        return basemap_places(feature, resolution);
      case 'countries':
        return [basemap_underline, basemap_countries];
      case 'coordlines':
        return [basemap_undercoord, basemap_coordlines];
      case 'coords':
        return basemap_coords(feature, resolution);
      case 'land':
        // return basemap_land;
        return null;
    }
    return null;
  };
